<template>
  <div>
    <Head/>
    <section>
      <div class="settingsContainer">
        <div class="settingsTitleContainer">设置</div>
        <div class="settingsDetailContainer">
          <div v-for="(item, index) in settingsList" :key="index">
            <div v-if="item.childs.length==0" class="noChildsSettingsItemContainer">
              <div>
                <div class="settingsTitle">
                  <div>{{ item.name_translate }}</div>
                  <div class="questionMark">?</div>
                </div>
                <div class="settingsExplain">{{ item.explain }}</div>
              </div>
              <el-switch
                  v-model="item.status"
                  active-color="#B4272B"
                  active-text="开"
                  inactive-color="#ff4949"
                  inactive-text="关"
                  style="display: block"
                  @change="functionChange(item.id)">
              </el-switch>
            </div>
            <div v-else class="childsSettingsItemContainer">
              <div class="settingsTitle">
                <div>{{ item.name_translate }}</div>
                <div class="questionMark">?</div>
              </div>
              <div class="childsListContainer">
                <div v-for="(childsItem,childsIndex) in item.childs" :key="childsIndex" class="childsItemContainer">
                  <div>{{ childsItem.name_translate }}</div>
                  <el-switch
                      v-model="childsItem.status"
                      active-color="#B4272B"
                      active-text="开"
                      inactive-text="关"
                      style="display: block"
                      @change="functionChange(childsItem.id)">
                  </el-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/head/pluginHomePageTop.vue";
import websiteUrl from "@/api/wangZhi";

export default {
  components: {Head},
  data() {
    return {
      settingsList: []
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let url = websiteUrl + '/plugin/set/settingsList';
      axios.post(url, {}, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          'ClientToken': 'Bearer ' + this.$route.query.token
        },
      }).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.settingsList = res.data;
      })
    },
    functionChange(id) {
      let url = websiteUrl + '/plugin/set/settingsUpdateStatus';
      axios.post(url, {
        function_id: id,
      }, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          'ClientToken': 'Bearer ' + this.$route.query.token
        },
      }).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.getList();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  margin-top: 20px;

  .settingsContainer {
    border-radius: 10px;
    width: 1400px;
    margin: 0 auto;
    background: #fff;

    .settingsTitleContainer {
      height: 70px;
      border-bottom: 1px solid #DFDFDF;
      line-height: 70px;
      padding-left: 30px;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
    }

    .settingsDetailContainer {
      padding: 0 30px 30px;

      .noChildsSettingsItemContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        border-bottom: 1px solid #DFDFDF;
        padding-right: 30px;

        /deep/ .el-switch {
          height: auto;
        }
      }

      .childsSettingsItemContainer {
        border-bottom: 1px solid #DFDFDF;
        padding-top: 30px;

        .childsListContainer {
          padding: 6px 30px 0;

          .childsItemContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #DFDFDF;
            height: 56px;

            div:first-child {
              font-size: 14px;
              color: #666666;
            }

            /deep/ .el-switch {
              height: auto;
            }
          }

          .childsItemContainer:last-child {
            border-bottom: none;
          }
        }
      }

      .settingsTitle {
        display: flex;
        align-items: center;

        div:first-child {
          color: #000000;
          margin-right: 9px;
        }
      }

      .settingsExplain {
        margin-top: 10px;
        font-size: 14px;
        color: #666666;
      }
    }
  }
}
</style>